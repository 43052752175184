import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ResponseDisplay from '../ResponseDisplay';

const style = {
    input: {
        background: "white",
        borderRadius:"4px"
    },
        '&.Mui-checked': {
            color: "rebeccapurple"
        },
        '&.Mui-focused':{
            color: "rebeccapurple"
        },
  };

export const ExchangeForm = () => {
  const [mpValue, setMPValue] = React.useState('A');
  const [mealValue, setMealValue] = React.useState('breakfast');
  const [mealPlans, setMealPlans] = React.useState();
  const [starch, setStarch]=React.useState(0)
  const [protein, setProtein]=React.useState(0)
  const [vegetable, setVegetable]=React.useState(0)
  const [fruit, setFruit]=React.useState(0)
  const [fat, setFat]=React.useState(0)
  const [dairy, setDairy]=React.useState(0)
  const [dessert, setDessert]=React.useState(0)
  const [response, setResponse] = React.useState()


  function setActiveMenu (response) {
        var mealPlan = response.filter(mp => {
            if(mp.plan === mpValue && mp.meal === mealValue){
                return mp
            }
        })
        console.log(mealPlan)
        if(mealPlan.length == 0){
            setStarch(0)
            setProtein(0)
            setVegetable(0)
            setFruit(0)
            setFat(0)
            setDessert(0)
            setDairy(0)
        } else {
            setStarch(mealPlan[0].starch)
            setProtein(mealPlan[0].protein)
            setVegetable(mealPlan[0].vegetable)
            setFruit(mealPlan[0].fruit)
            setFat(mealPlan[0].fat)
            setDessert(mealPlan[0].dessert)
            setDairy(mealPlan[0].dairy)
        }  
  }

  function updateActiveMenu(activeMealPlan, activeMeal){
    if(mealPlans){
        var mealPlan = mealPlans.filter(mp => {
            if(mp.plan === activeMealPlan && mp.meal === activeMeal){
                return mp
            }
        })
        if(mealPlan.length == 0){
            setStarch(0)
            setProtein(0)
            setVegetable(0)
            setFruit(0)
            setFat(0)
            setDessert(0)
            setDairy(0)
        } else {
            setStarch(mealPlan[0].starch)
            setProtein(mealPlan[0].protein)
            setVegetable(mealPlan[0].vegetable)
            setFruit(mealPlan[0].fruit)
            setFat(mealPlan[0].fat)
            setDessert(mealPlan[0].dessert)
            setDairy(mealPlan[0].dairy)
        }
  }
}

  const callGateway = React.useCallback(async () => {
    let response = await fetch('https://801dr1fstj.execute-api.us-east-1.amazonaws.com/default/getMealPlans')
    response  = await response.json()
    setMealPlans(response)
    setActiveMenu(response)

  }, [mealPlans]) 

  const submitRequested = React.useCallback( async () => {
    const requested = {
      meal: mealValue,
      starch: parseInt(starch),
      protein: parseInt(protein),
      vegetable: parseInt(vegetable),
      fruit: parseInt(fruit),
      fat: parseInt(fat),
      dairy: parseInt(dairy),
      dessert: parseInt(dessert)
    }
    console.log(requested)
    let response = await fetch('https://801dr1fstj.execute-api.us-east-1.amazonaws.com/default/selectExchanges', {"method": "POST", "body": JSON.stringify(requested)})
    response = await response.json()
    console.log(response)
    setResponse(response)
  })

  React.useEffect(() => {
    callGateway()

  }, [])

  const handleMPChange = (event) => {
    setMPValue((event.target.value));
    updateActiveMenu(event.target.value, mealValue)
  };

  const handleMealChange = (event) => {
    setMealValue((event.target.value));
    updateActiveMenu(mpValue, event.target.value)
  };

  return (
    <Box>
    <Grid container>
    <Grid md={6} xs={12}>
    <Stack alignItems={"center"}>

    <FormControl>
      <FormLabel  sx={{...style, color: "rebeccapurple"}} id="demo-controlled-radio-buttons-group">Meal Plan</FormLabel>
      <RadioGroup 
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={mpValue}
        onChange={handleMPChange}
      >
        <FormControlLabel value="A" control={<Radio sx={{...style}}/>} label="A" />
        <FormControlLabel value="B" control={<Radio sx={{...style}}/>} label="B" />
        <FormControlLabel value="C" control={<Radio sx={{...style}} />} label="C" />
      </RadioGroup>
      <FormLabel  sx={{...style,color: "rebeccapurple"}} id="demo-controlled-radio-buttons-group">Meal</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={mealValue}
        onChange={handleMealChange}
      >
        <FormControlLabel value="breakfast" control={<Radio sx={{...style}}/>} label="Breakfast" />
        <FormControlLabel value="lunch" control={<Radio sx={{...style}}/>} label="Lunch" />
        <FormControlLabel value="dinner" control={<Radio sx={{...style}}/>} label="Dinner" />
        <FormControlLabel value="snack" control={<Radio sx={{...style}} />} label="Snack" />
      </RadioGroup>
      <TextField sx={{...style}} onChange={ e => {
        setStarch(e.target.value)
      }} value={starch} id="starch" label="Starches" variant="outlined" style={{padding: "10px"}}/>
      <TextField sx={{...style}} onChange={e => {
        setProtein(e.target.value)
      }} value={protein} id="protein" label="Proteins" variant="outlined" style={{padding: "10px"}}/>
      <TextField sx={{...style}} onChange={e => {
        setVegetable(e.target.value)
      }} value={vegetable} id="vegetable" label="Vegetables" variant="outlined" style={{padding: "10px"}}/>
      <TextField sx={{...style}} onChange={e => {
        setFruit(e.target.value)}} value={fruit} id="fruit" label="Fruits" variant="outlined" style={{padding: "10px"}}/>
      <TextField  sx={{...style}} onChange={e => { setFat(e.target.value)}} value={fat} id="fat" label="Fats" variant="outlined" style={{padding: "10px"}}/>
      <TextField sx={{...style}} onChange={e => {setDessert(e.target.value)}} value={dessert} id="dessert" label="Desserts" variant="outlined" style={{padding: "10px"}}/>
      <TextField sx={{...style}} onChange={e => { setDairy(e.target.value)}} value={dairy} id="dairy" label="Dairys" variant="outlined" style={{padding: "10px"}}/>
    </FormControl>
    <Button  sx={{color: "rebeccapurple"}} onClick={submitRequested}>Submit</Button>
    </Stack>
    </Grid>
    <Grid md={6} xs={12}>
    <ResponseDisplay key={response} response={response}/>
    </Grid>
    </Grid>
    </Box>
  );
}

export default ExchangeForm