import logo from './logo.svg';
import './App.css';
import ExchangeGenerator from './components/ExchangeGenerator';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const THEME = createTheme({
  background : "mediumpurple",
  color: "lightgray",
  typography: {
   "fontFamily": `"Noto Serif", "Helvetica", "Arial", sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={THEME}>
      <ExchangeGenerator/>
      </ThemeProvider>
    </div>
  );
}

export default App;
