import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExchangeForm from '../ExchangeForm';
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  
export const ExchangeGenerator = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <Box sx={{
          backgroundColor: "mediumpurple",
          color: "lightgrey",
          width: "100%"
      }}>
        <Typography variant="h1" gutterBottom>
          Exchange Generator
        </Typography>
        <Button sx={{color: "rebeccapurple"}} onClick={handleOpen}>About</Button>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
  <Box sx={{ ...style, width: 400 }}>
    <h2 id="parent-modal-title">About this project</h2>
    <p id="parent-modal-description">
      Exchange Generator was inspired by Renfrew's exchange-based meal plan system. At Renfrew, patients are assigned a meal plan (A, B, or C), and each meal on the plan consists of a number of various exchanges.
    </p>
    <h2>How to use this generator</h2>
    <p>Select your meal plan and meal (breakfast, lunch, dinner) to populate the number of exhanges in each category recommended by your meal plan. Snack defaults to 0 exchanges in each category. You can edit the number of exchanges for each category by entering a number value. Hit 'Submit' to generate random exchange selections.</p>
    <h2>Coming soon</h2>
    <p>More exchange selections and desserts! Desserts currently do not return any results :( . Email me at emily@emilylaughs.com for suggestions.</p>
    <h2>Disclaimer</h2>
    <p>I am not at all affiliated with Renfew (except for being a patient) and I am not a dietician. This tool is not meant to provide any actual dietary guidance and is meant as a fun way to generate meal suggestions that meet Renfrew's meal plans. Enjoy!</p>
    <Button onClick={handleClose}>Close</Button>

  </Box>
</Modal>
        <ExchangeForm />
      </Box>
    );
  }

export default ExchangeGenerator