import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

export const ResponseDisplay = (key) => {
    const response = key.response
    return (
        <Box sx={{ alignItems: "center", width: '100%' }}>
          <Typography variant="h2" gutterBottom>
            Generated Exchanges
          </Typography>
          <List>
            <ListItem style={{justifyContent:"center"}}>
                <Typography variant='h6'>Starches</Typography>
                <List>
                    {response ? 
                    
                    response.starch !== null ? response.starch.map(s => {
                        return(<ListItem key={s.name}><Typography>{s.name}</Typography></ListItem>)
                    }) : <ListItem><Typography>none</Typography></ListItem>
                    
                    : <ListItem><Typography>none</Typography></ListItem> }
                </List>
            </ListItem>
            <ListItem style={{justifyContent:"center"}}>
                <Typography variant='h6'>Proteins</Typography>
                <List>
                    {response ? 
                    
                    response.protein !== null ? response.protein.map(s => {
                        return(<ListItem key={s.name}><Typography>{s.name}</Typography></ListItem>)
                    }) : <ListItem><Typography>none</Typography></ListItem>
                    
                    : <ListItem><Typography>none</Typography></ListItem> }
                </List>
            </ListItem>
            <ListItem style={{justifyContent:"center"}}>
                <Typography variant='h6'>Vegetables</Typography>
                <List>
                    {response ? 
                    
                    response.vegetable != null ? response.vegetable.map(s => {
                        return(<ListItem key={s.name}><Typography>{s.name}</Typography></ListItem>)
                    }) : <ListItem><Typography>none</Typography></ListItem>
                    
                    : <ListItem><Typography>none</Typography></ListItem> }
                </List>
            </ListItem>
            <ListItem style={{justifyContent:"center"}}>
                <Typography variant='h6'>Fruits</Typography>
                <List>
                    {response ? 
                    
                    response.fruit != null ? response.fruit.map(s => {
                        return(<ListItem key={s.name}><Typography>{s.name}</Typography></ListItem>)
                    }) : <ListItem><Typography>none</Typography></ListItem>
                    
                    : <ListItem><Typography>none</Typography></ListItem> }
                </List>
            </ListItem>
            <ListItem style={{justifyContent:"center"}}>
                <Typography variant='h6'>Fats</Typography>
                <List>
                    {response ? 
                    
                    response.fat != null ? response.fat.map(s => {
                        return(<ListItem key={s.name}><Typography>{s.name}</Typography></ListItem>)
                    }) : <ListItem><Typography>none</Typography></ListItem>
                    
                    : <ListItem><Typography>none</Typography></ListItem> }
                </List>
            </ListItem>
            <ListItem style={{justifyContent:"center"}}>
                <Typography variant='h6'>Dairys</Typography>
                <List>
                    {response ? 
                    
                    response.dairy != null ? response.dairy.map(s => {
                        return(<ListItem key={s.name}><Typography>{s.name}</Typography></ListItem>)
                    }) : <ListItem><Typography>none</Typography></ListItem>
                    
                    : <ListItem><Typography>none</Typography></ListItem> }
                </List>
            </ListItem>
            <ListItem style={{justifyContent:"center"}}>
                <Typography variant='h6'>Desserts</Typography>
                <List>
                    {response ? 
                    
                    response.dessert != null ? response.dessert.map(s => {
                        return(<ListItem key={s.name}><Typography>{s.name}</Typography></ListItem>)
                    }) : <ListItem><Typography>none</Typography></ListItem>
                    
                    : <ListItem><Typography>none</Typography></ListItem> }
                </List>
            </ListItem>
          </List>
        </Box>
      );

}

export default ResponseDisplay